@import "../../../../../public/mixins/mixins.scss";

.serviceContainer {
  @include flexContainer(row, unset, center, 0px, 40px);
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 3px 8px 6px #f4f4f4;
  // width: calc(50% - 20px);
  flex: 1;
  @include mobile() {
    column-gap: 10px;
    height: auto !important;
    padding: 10px;
  }

  .serviceImgContainer {
    width: 220px;
    flex-shrink: 0;
    align-self: stretch;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    @include mobile() {
      width: 120px;
    }
  }
  .serviceInfoContainer {
    padding: 15px 0px;
    @include mobile() {
      padding: 8px;
    }
    .serviceNameType1 {
      @include font(30px, 600, #4a536a, 1.2px);
      @include mobile() {
        font-size: 16px;
      }
    }
    .serviceNameType2 {
      @include font(18px, 700, #006daf, 0.72px);
      @include mobile() {
        font-size: 16px;
      }
    }

    .heading {
      @include font(14px, 400, #4a536a, 0.56px);
      @include mobile() {
        font-size: 12px;
      }
      margin-bottom: 0px;
    }

    .subHeading {
      @include font(12px, 400, #666161, 0.48px, 24px);
      @include mobile() {
        font-size: 12px;
        line-height: 16px;
      }
      text-align: justify;
    }

    .serviceBtn {
      @include buttonType1(10px 25px, 20px);
      @include mobile() {
        @include buttonType1(3px 15px, 10px);
      }
      text-decoration: none;
      display: inline-block;
    }
  }
}
